<template>
    <div class="content">
        <div class="header">
            <div class="title">{{ $t('meeting_session.subject') }}</div>
            <slot name="header_menu_right"></slot>
        </div>
        <div class="body">
            <div class="container-form">
        
                <div class="table-list">
                    <div class="row-item" v-for="topic in data">
                        <div class="column-icon">
                            <icon-project v-if="topic.type === 'project'" />
                            <icon-objective v-else-if="topic.type === 'objective'" />
                            <icon-key-result v-else-if="topic.type === 'key_result'" />
                            <icon-task v-else-if="topic.type === 'task'" />
                            <icon-custom v-else-if="topic.type === 'custom'" />
                            <icon-check-list v-else-if="topic.type === 'weekly_evaluation' || topic.type === 'past_day_evaluation'" />
                        </div>
                        <div class="column-name">
                            <template v-if="topic.type === 'weekly_evaluation'">
                                {{ $t('meeting_session.topic.weekly_evaluation') }}
                            </template>
                            <template v-else-if="topic.type === 'past_day_evaluation'">
                                {{ $t('meeting_session.topic.previous_day_assessment') }}
                            </template>
                            <template v-else>
                                {{ topic.name }}
                            </template>
                        </div>
                        <div class="column-type">
                            {{ $t(`meeting_session.single_topic.${topic.type}`) }}
                        </div>
                    </div>
                </div>
                <slot name="form_submit"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import IconProject from '@/components/Icons/Projects'
import IconObjective from '@/components/Icons/Objectives'
import IconTask from '@/components/Icons/Task'
import IconCustom from '@/components/Icons/Edit'
import IconCheckList from '@/components/Icons/CheckList'
import IconKeyResult from '@/components/Icons/KeyResult'

export default {
    props: {
        optionsUsers: Array,
        data: Array
    },
    components: {
        IconProject,
        IconObjective,
        IconTask,
        IconCustom,
        IconCheckList,
        IconKeyResult
    },
    data() {
        return {
            loaded: false,
            loadedFinish: false,
        }
    },
    async mounted() {
        this.loaded = true;
    },
    methods: {
        changeStepFunction(direction, stepNo = false) {
            if(direction) { 
                this.$emit(direction);
            } else if(stepNo) {
                this.$emit('goToStep', stepNo)
            }
        },
        changeStepOrRedirect(changeStep, withRedirect, userSlug) {
            if(changeStep) {
                this.$nextTick(() => {
                    if(!changeStep.step) {
                        this.$emit(changeStep.direction);
                    } else {
                        this.$emit('goToStep', changeStep.step)
                    }
                });
            }

            if(withRedirect) {
                if(this.$route.query.department_id){
                    this.$router.push({name: 'company'})
                }else{
                    this.$router.push({name: 'user-show', params: { slug: userSlug }})
                }
            }
        },
    }
}
</script>